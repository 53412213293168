import 'ol/ol.css';
import {Map, View} from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import Point from 'ol/geom/Point';
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import {fromLonLat} from "ol/proj";
import {defaults as defaultControls} from "ol/control";
import Popup from "ol-popup/src/ol-popup";
import Control from "ol/control/Control";
import GeoJSON from "ol/format/GeoJSON";
import {Circle, Icon, Stroke, Fill, Style} from "ol/style"

import 'ol-popup/src/ol-popup.css'

import IMG_yellowTruck from "/data/img/yellowTruck.png";
import yellowTruckCollection from "/data/yellowTruckCollection.geojson";
import greenTruckCollection from "/data/greenTruckCollection.geojson";

const map = new Map({
    
    controls: defaultControls({
        zoom: true,
        attribution: true,
        rotate: true
    }),
    target: 'map',
    layers: [
        new TileLayer({
            source: new OSM()
        })
    ],
    view: new View({
        center: fromLonLat([10.447683, 51.163361]),
        zoom: 6
    })
});

let button = document.createElement('div');
button.innerHTML = 'Made by N.Stiens';

let myElement = document.createElement('div');
myElement.className = 'ol-attribution ol-unselectable ol-control ol-attribution-left';
myElement.collabsible = false;
myElement.appendChild(button);

let myControl = new Control({element: myElement});
map.addControl(myControl);

//Yellow Trucks
let style_yellowTrucks = new Style({
    image: new Icon({
        crossOrigin: 'anonymous',
        src: IMG_yellowTruck,
    })
});
let source_yellowTrucks = new VectorSource({
    format: new GeoJSON(),
    url: yellowTruckCollection,
});
let layer_yellowTrucks = new VectorLayer({
    source: source_yellowTrucks,
    style: style_yellowTrucks,
});
map.addLayer(layer_yellowTrucks);

//Green Trucks
let style_greenTrucks = new Style({
    image: new Circle({
        fill: new Fill({
            color: '#26ac06',
        }),
        stroke: new Stroke({
            color: '#26ac06',
            width: 1.25
        }),
        radius: 4.5
    }),
});
let source_greenTrucks = new VectorSource({
    format: new GeoJSON(),
    url: greenTruckCollection,
});
let layer_greenTrucks = new VectorLayer({
    source: source_greenTrucks,
    style: style_greenTrucks,
});
map.addLayer(layer_greenTrucks);

//Popup Feature
let popup = new Popup();
map.addOverlay(popup);
map.on('click', function(evt) {
    let selectedTruck = map.forEachFeatureAtPixel(
        evt.pixel,
        function(ft, layer){return ft;}
    );
    if (selectedTruck && selectedTruck.getGeometry().getType() === 'Point') {
        let coord = selectedTruck.getGeometry().getCoordinates();

        let properties = selectedTruck.getProperties();

        let content =
            '<p>' + selectedTruck.get('truck_name') + '</p>' +
            '<p>' + selectedTruck.get('regv_name')+ '</p>';

        if (selectedTruck.get('tossout_time')){
            content += '<p> Zeitpunkt: ' + selectedTruck.get('tossout_time') + '</p>';
        }

        popup.show(coord, content);
        refreshSources();
    } else {
        popup.hide();
        refreshSources();
    }
});

function refreshSources(){
    source_yellowTrucks.refresh();
    source_greenTrucks.refresh();
}

function updateData() {
    setInterval(function () {
        refreshSources();
    }, 30 * 1000);
}

updateData();